/* eslint-disable no-fallthrough */
import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useGetEventsQuery } from 'services/events/api';
import { useGetNewsQuery } from 'services/news/api';
import links from 'utils/links';

const UpcomingEventsList = () => {
  const { data: events } = useGetEventsQuery({});
  const { data: news } = useGetNewsQuery();
      
  return (
    <Table responsive>
      <tbody>
        {
          [...(news || [])]
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .slice(0, 10)
            .map(n => {
              let icon: JSX.Element | null = null;
              let text;
              let description;
              switch (n.type) {
                case 'event.created': {
                  const eventCreatedNews = n;
                  icon = <FontAwesomeIcon icon="circle-plus" style={{ color: 'green' }} />;
                  text = <div>New Event</div>;
                  description = (
                    <div>
                      <Link to={links.event(eventCreatedNews.event.id)}>{ eventCreatedNews.event.name }</Link>
                    </div>
                  );
                  break;
                }
                case 'fight.created': {
                  const fightCreatedNews = n;
                  const event = events?.find(e => e.id === fightCreatedNews.fight.eventId);
                  icon = <FontAwesomeIcon icon="circle-plus" style={{ color: 'green' }} />;
                  text = <div>New Fight</div>;
                  description = (
                    <div>
                      <Link to={links.fighter(fightCreatedNews.fight.red.id)}>
                        { fightCreatedNews.fight.red.name }
                      </Link>
                      &nbsp;vs&nbsp;
                      <Link to={links.fighter(fightCreatedNews.fight.blue.id)}>
                        { fightCreatedNews.fight.blue.name }
                      </Link>&nbsp;
                      { event && <span>on <Link to={links.event(event?.id)}>{ event?.date }</Link></span> }
                    </div>
                  );
                  break;
                }
                case 'event.updated': {
                  const eventUpdatedNews = n;
                  icon = <FontAwesomeIcon icon="warning" style={{ color: 'yellow' }} />;
                  text = <div>Event Updated</div>;
                  description = (
                    <div>
                      { eventUpdatedNews.event.name } on { eventUpdatedNews.event.date }
                    </div>
                  );
                  break;
                }
                case 'fight.updated': {
                  const fightUpdatedNews = n;
                  const event = events?.find(e => e.id === fightUpdatedNews.fight.eventId);
                  icon = <FontAwesomeIcon icon="warning" style={{ color: 'yellow' }} />;
                  text = <div>Fight Updated</div>;
                  description = (
                    <div>
                      <Link to={links.fighter(fightUpdatedNews.fight.red.id)}>
                        { fightUpdatedNews.fight.red.name }
                      </Link>
                      &nbsp;vs&nbsp;
                      <Link to={links.fighter(fightUpdatedNews.fight.blue.id)}>
                        { fightUpdatedNews.fight.blue.name }
                      </Link>&nbsp;
                      { event && <span>on <Link to={links.event(event?.id)}>{ event?.date }</Link></span> }
                    </div>
                  );
                  break;
                }
                case 'event.deleted': {
                  const eventDeletedNews = n;
                  icon = <FontAwesomeIcon icon="circle-xmark" style={{ color: 'red' }} />;
                  text = <div>Event Canceled</div>;
                  description = (
                    <div>
                      { eventDeletedNews.event.name } on { eventDeletedNews.event.date }
                    </div>
                  );
                  break;
                }
                case 'fight.deleted': {
                  const fightDeletedNews = n;
                  const event = events?.find(e => e.id === fightDeletedNews.fight.eventId);
                  icon = <FontAwesomeIcon icon="circle-xmark" style={{ color: 'red' }} />;
                  text = <div>Fight Canceled</div>;
                  description = (
                    <div>
                      <Link to={links.fighter(fightDeletedNews.fight.red.id)}>
                        { fightDeletedNews.fight.red.name }
                      </Link>
                      &nbsp;vs&nbsp;
                      <Link to={links.fighter(fightDeletedNews.fight.blue.id)}>
                        { fightDeletedNews.fight.blue.name }
                      </Link>&nbsp;
                      { event && <span>on <Link to={links.event(event?.id)}>{ event?.date }</Link></span> }
                    </div>
                  );
                  break;
                }
              }
              return (
                <tr
                  key={n.id}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <td width={125}>
                    <b>{ DateTime.fromISO(n.createdAt).toFormat('yyyy-MM-dd') }</b>
                  </td>
                  <td width={30}>
                    { icon }
                  </td>
                  <td width={140}>
                    { text }
                  </td>
                  <td>
                    { description }
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
};

export default UpcomingEventsList;
