import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import RBForm from 'react-bootstrap/Form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { useGetEventsQuery } from 'services/events/api';
import { useGetFightsQuery } from 'services/fights/api';
import { formatYYYYMMDD } from 'utils/date';
import {
  useAddContestMutation,
  useGetContestsQuery,
  usePatchContestMutation,
  useUpdateContestMutation,
} from 'services/contests/api';

const ContestForm = ({
  contestId,
  onClose,
}) => {
  const { data: contests } = useGetContestsQuery();
  const contest = contests?.find(c => c.id === contestId);
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>(contest?.eventId);
  const [startsAtTs, setStartsAtTs] = useState(
    contest
    ? DateTime.fromISO(contest.startsAt).toJSDate()
    : null,
  );
  const [endsAtTs, setEndsAtTs] = useState(
    contest
    ? DateTime.fromISO(contest.endsAt).toJSDate()
    : null,
  );
  const { data: events } = useGetEventsQuery({
    filter: {
      date: { $gte: formatYYYYMMDD() },
    },
  });
  const { data: fights } = useGetFightsQuery({
    filter: selectedEventId
      ? { eventId: selectedEventId }
      : undefined,
  });

  const [addContest] = useAddContestMutation();
  const [patchContest] = usePatchContestMutation();
  const [updateContest] = useUpdateContestMutation();

  const handleChangeEventId = e => setSelectedEventId(e.target.value);

  const onSubmit = async values => {
    values.betValue = parseInt(values.betValue, 10);
    if (contest && contest.id) {
      updateContest({
        id: contest.id,
        ...values,
      })
        .then(() => onClose());
    } else {
      addContest(values)
        .then(() => onClose());
    }
  };

  return (
    <Form
      initialValues={contest}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RBForm.Group>
            <RBForm.Label>Title</RBForm.Label>
            <Field
              className="form-control"
              component="input"
              name="title"
            />
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Main image</RBForm.Label>
            <Field
              className="form-control"
              component="input"
              name="mainImage"
            />
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Event</RBForm.Label>
            <Field
              className="form-control"
              component="select"
              name="eventId"
              onInput={handleChangeEventId}
            >
              <option aria-label="default" />
              {
                events?.sort((e1, e2) => (e1.date < e2.date ? -1 : 1))
                  .map(event => (
                    <option
                      key={event.id}
                      value={event.id}
                    >
                      {event.name}
                    </option>
                  ))
              }
            </Field>
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Fights</RBForm.Label>
            <FieldArray
              className="form-control"
              name="fights"
            >
              {({ fields }) => {
                return (
                  <div>
                    {
                      fields.map((name, index) => (
                        <table key={name}>
                          <tbody>
                            <tr>
                              <td>
                                <Field
                                  className="form-control"
                                  component="select"
                                  name={`${name}.id`}
                                >
                                  <option aria-label="default" />
                                  {
                                    fights?.sort((f1, f2) => (f1.date < f2.date ? -1 : 1))
                                      .map(fight => (
                                        <option
                                          key={fight.id}
                                          value={fight.id}
                                        >
                                          {fight.red.name} vs {fight.blue.name}
                                        </option>
                                      ))
                                  }
                                </Field>
                              </td>
                              <td width={100}>
                                <Field
                                  className="form-control"
                                  component="select"
                                  name={`${name}.maxNbRounds`}
                                >
                                  <option value="3">3</option>
                                  <option value="5">5</option>
                                </Field>
                              </td>
                              <td width={35}>
                                <FontAwesomeIcon
                                  icon="times"
                                  onClick={() => fields.remove(index)}
                                  size="1x"
                                  style={{
                                    color: '#ce0000',
                                    cursor: 'pointer',
                                    marginLeft: 10,
                                  }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))
                    }
                    <Button
                      onClick={() => fields.push({ id: '', maxNbRounds: 3 })}
                    >
                      Add
                    </Button>
                  </div>
                );
              }}
            </FieldArray>
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Starts</RBForm.Label>
            <Field
              component={
                ({ input }) => (
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={startsAt => {
                      const timestamp = DateTime.fromJSDate(startsAt);
                      if (contest && contest.id) {
                        patchContest({
                          id: contest.id,
                          startsAt: timestamp.toISO(),
                        });
                      }
                      setStartsAtTs(timestamp.toJSDate());
                      input.onChange(timestamp.toJSDate());
                    }}
                    selected={startsAtTs}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    timeIntervals={30}
                  />
                )
              }
              defaultValue={startsAtTs}
              name="startsAt"
            />
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Ends</RBForm.Label>
            <Field
              component={
                ({ input }) => (
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd HH:mm"
                    onChange={endsAt => {
                      const timestamp = DateTime.fromJSDate(endsAt);
                      if (contest && contest.id) {
                        patchContest({
                          id: contest.id,
                          endsAt: timestamp.toISO(),
                        });
                      }
                      setEndsAtTs(timestamp.toJSDate());
                      input.onChange(timestamp.toJSDate());
                    }}
                    selected={endsAtTs}
                    showTimeSelect
                    timeCaption="Time"
                    timeFormat="HH:mm"
                    timeIntervals={30}
                  />
                )
              }
              defaultValue={endsAtTs}
              name="endsAt"
            />
          </RBForm.Group>
          <RBForm.Group>
            <RBForm.Label>Bet value</RBForm.Label>
            <Field
              name="betValue"
              component="input"
              defaultValue={contest && contest.betValue}
              className="form-control"
              style={{ width: 100 }}
            />
          </RBForm.Group>
          <div
            style={{
              float: 'right',
              bottom: 10,
              paddingBottom: 15,
              position: 'absolute',
              right: 30,
            }}
          >
            <Button variant="outline-secondary" onClick={() => onClose()}>Cancel</Button>
            &nbsp;
            {
              contest
                ? <Button type="submit" variant='success'>Save</Button>
                : <Button type="submit" variant='success'>Create</Button>
            }
          </div>
        </form>
      )}
    />
  );
};

export default ContestForm;
